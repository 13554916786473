import React from 'react';
import styled from 'styled-components';

// Background Image URL - Related to Business/Networking/Event
const BACKGROUND_IMAGE_URL = 'https://images.unsplash.com/photo-1542744173-05336fcc7ad4?ixlib=rb-4.0.3&auto=format&fit=crop&w=1950&q=80';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  padding: 2rem;
  background-image: url(${BACKGROUND_IMAGE_URL});
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(44, 62, 80, 0.7); /* Semi-transparent overlay */
    z-index: 1;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Header = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const SubHeader = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 3rem;
  max-width: 700px;
  line-height: 1.5;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
`;

const CoolText = styled.div`
  font-size: 2rem;
  font-weight: bold;
  background: linear-gradient(90deg, #ff6f61, #dce35b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
`;

const HomePage = () => {
  return (
    <Container>
      <ContentWrapper>
        <Header>Find Your First Customers</Header>
        <SubHeader>
          Helping solopreneurs and indie hackers connect with their first customers through innovative event-based promotions.
        </SubHeader>
        <CoolText>
          Unlock the Future of Your Startup
        </CoolText>
      </ContentWrapper>
    </Container>
  );
};

export default HomePage;
