
import './App.css';
import StartupCard from './components/StartupCard';
import HomePage from './components/Home';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/get",
      element: <StartupCard/>,
    },
  ])
    return (
      <div>
        <RouterProvider router={router} />
    </div>
  );
}

export default App;
